import * as React from 'react';

import { useAuthState, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useSearchParams } from 'react-router-dom';
import {
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset
} from "firebase/auth";

import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import { auth, useApiToken } from '../services/firebase';
import Main from './Main';
import Link from './Link';


export default function App() {
  const [user, loadingAuth, authError] = useAuthState(auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const apiToken = useApiToken();
  const mode = searchParams.get("mode");

  return (<>
    {!authError && !loadingAuth && user && !apiToken.loading && !apiToken.error && (
      <Main user={user} apiToken={apiToken} />
    )}
    {!loadingAuth && !user && (mode === 'resetPassword') &&
      <ResetPassword oobCode={searchParams.get("oobCode")} setSearchParams={setSearchParams} />
    }
    {!loadingAuth && !user && (mode !== 'resetPassword') &&
      <SignIn />
    }
    {authError && <Alert>Login error: {authError}</Alert>}
  </>);
}

function SignIn() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(auth);

  const resetPassword = () => {
    if (email.length > 0) {
      alert(`A password reset email will be sent to ${email}.`);
      sendPasswordResetEmail(auth, email);
    }
  };

  return (
    <Container>
      <Typography variant="h5" sx={{ my: 6 }}>ZBrowser2 Login</Typography>
      <InputLabel htmlFor="email">Email</InputLabel>
      <Input
        type="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={{ m: 1 }}
      />
      <InputLabel htmlFor="password">Password</InputLabel>
      <Input
        type="password"
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ m: 1 }}
      /><br />
      <Button onClick={() => signInWithEmailAndPassword(email, password)}>
        Sign In
      </Button>
      <Button onClick={resetPassword} color="secondary">
        Reset password
      </Button>
      {error && <Alert severity="error" sx={{ mt: 4 }}>Login error.  Please verify your account credentials.</Alert>}
    </Container>
  );
}

function ResetPassword({ oobCode, setSearchParams }) {
  const [submitted, setSubmitted] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(auth);

  React.useEffect(() => {
    // Verify the password reset code is valid, only do this once.
    verifyPasswordResetCode(auth, oobCode)
      .then(setEmail)
      .catch((error) => {
        console.log(error.toString());
        setMessage(error.toString());
      });
  });

  const handleResetPassword = () => {
    // https://example.com/usermgmt?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy...&lang=fr
    setSubmitted(true);
    if (password !== passwordConfirmation) {
      setMessage('Passwords do not match.');
    } else {
      // Save the new password.
      confirmPasswordReset(auth, oobCode, password).then((resp) => {
        // Password reset has been confirmed and new password updated.
        setSearchParams({});
        signInWithEmailAndPassword(email, password);
      }).catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        console.log(error.toString());
        setMessage(error.toString());
      });
    }
  }

  return (
    <Container>
      <Typography variant="h5" sx={{ my: 6 }}>ZBrowser2 Password Reset</Typography>
      {message &&
        <>
          <Alert severity="error">{message}</Alert>
          <Button component={Link} to="/~msk/zbrowser2/">Return to login page</Button>
        </>
      }
      {email && !submitted &&
        <>
          <InputLabel htmlFor="password">New password for {email}</InputLabel>
          <Input
            type="password"
            id="password"
            value={password}
            onChange={(e) => { setPassword(e.target.value); setMessage(null) }}
            sx={{ m: 1 }}
          /><br />
          <InputLabel htmlFor="passwordConfirmation">Confirm new password</InputLabel>
          <Input
            type="password"
            id="password-confirmation"
            value={passwordConfirmation}
            onChange={(e) => { setPasswordConfirmation(e.target.value); setMessage(null); }}
            sx={{ m: 1 }}
          /><br />
          <Button onClick={handleResetPassword}>
            Reset password
          </Button>
        </>
      }
    </Container>
  );
}