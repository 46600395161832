import React from 'react';

export function encodeParameter(parameter) {
  if (parameter) {
    return parameter.replace('/', '_');
  } else {
    return "";
  }
}

export function decodeParameter(parameter) {
  if (parameter) {
    return parameter.replace('_', '/');
  } else {
    return null;
  }
}

export function phaseSchleicherMarcus(th) {
  // th in degrees, Phi in mag
  return -[3.00977231e-15, 4.77914601e-13, -4.19820551e-10, 6.91889188e-08,
    -5.40348305e-06, 6.26806666e-04, -4.75261424e-02, 6.55815711e-03].map(
      (c, i) => c * th ** (7 - i)
    ).reduce((sum, cur) => (sum + cur));
}

// two months = 60 days = 5184000000 ms
export const cleanPhotometry = (rows, config) =>
  rows.filter(row => (
    row.m
    && row.merr
    && (row.m[config.aperture] > 0)
    && isFinite(row.m[config.aperture])
    && (config.plotAllPhotometry || row.merr[config.aperture] <= 0.36)
    && (
      (row.programid !== 1)
      || config.displayEmbargoed
      || ((new Date() - new Date(row.obsdate)) > 5184000000)
    )
  ));

/**
 * Formatting functions for MPChecker
 */
export const mpcFormat =
{
  /**
    * @param {float} ra decimal degrees
    */
  ra: (angle) => {
    const hours = angle / 15;
    let h = Math.floor(Math.abs(hours));
    let m = Math.floor((Math.abs(hours) - h) * 60);
    let s = ((Math.abs(hours) - h) * 60 - m) * 60;

    if (s >= 60) {
      s -= 60;
      m += 1;
    }

    if (m >= 60) {
      m -= 60;
      h += 1;
    }

    if (h >= 24) {
      h -= 24;
    }

    h = h.toFixed(0);
    m = m.toFixed(0);
    s = s.toFixed(1);

    h = '0'.repeat(2 - h.length) + h;
    m = '0'.repeat(2 - m.length) + m;
    s = '0'.repeat(4 - s.length) + s;

    return `${h} ${m} ${s}`;
  },

  /**
    * @param {float} dec decimal degrees
    */
  dec: (angle) => {
    const sign = (angle < 0) ? '-' : '+';
    let d = Math.floor(Math.abs(angle));
    let m = Math.floor((Math.abs(angle) - d) * 60);
    let s = ((Math.abs(angle) - d) * 60 - m) * 60;

    if (s >= 60) {
      s -= 60;
      m += 1;
    }

    if (m >= 60) {
      m -= 60;
      d += 1;
    }

    if (d >= 360) {
      d -= 360;
    }

    d = d.toFixed(0);
    m = m.toFixed(0);
    s = s.toFixed(1);

    d = sign + '0'.repeat(2 - d.length) + d;
    m = '0'.repeat(2 - m.length) + m;
    s = '0'.repeat(4 - s.length) + s;

    return `${d} ${m} ${s}`;

  }
}

function formatCsvCell(value) {
  if (typeof value === "string")
    return `"${value}"`;
  else
    return value;
}

/**
 * First item defines the table columns.
 */
export function useDownloadableTable(rows) {
  const [url, setURL] = React.useState(null);
  React.useEffect(() => {
    // https://javascript.info/blob
    if (rows && rows.length) {
      let csv = "";
      const columns = Object.keys(rows[0]);
      csv = csv.concat(columns.map((column) => `"${column}"`).join(','), "\n");
      csv = csv.concat(...rows.map((row) =>
        columns.map((column) =>
          formatCsvCell(row[column]))
          .join(',')
          .concat("\n")
      ));

      const blob = new Blob([csv], { type: 'text/csv' });
      setURL(URL.createObjectURL(blob));
      return () => {
        URL.revokeObjectURL(url);
      }
    }
  }, [rows]);
  return url;
}

export const yeas = (votes) => {
  return Object.values(votes || {})
    .filter((vote) => !vote.tentative)
    .reduce((total, vote) => total + vote.status, 0);
};

export const nays = (votes) => {
  return Object.values(votes || {})
    .filter((vote) => !vote.tentative)
    .reduce((total, vote) => total + !vote.status, 0);
};

export const tentative = (votes) => {
  return Object.values(votes || {})
    .reduce((total, vote) => total + (vote.tentative === true), 0);
};

export const disposition = (votes) => (yeas(votes) > nays(votes));

export const filterVotesByUser = ((votes, uid, invert) => (
  Object.fromEntries(
    Object.entries(votes)
      .filter(([_uid, vote]) => invert ? (_uid !== uid) : (_uid === uid))
  )
));

export function outburstTally(votes, users) {
  const y = yeas(votes);
  const t = yeas(votes, 'tentative');
  const n = nays(votes) - t;
  return {
    yeas: yeas(votes),
    tentative: tentative(votes),
    nays: nays(votes),
    disposition: disposition(votes),
    votesFor: users && votesFor(votes, users),
    votesTentative: users && votesTentative(votes, users),
    votesAgainst: users && votesAgainst(votes, users)
  };
}

export function votesFor(votes, users) {
  const tally = yeas(votes);
  const usersInFavor = (
    Object.entries(votes || {})
      .filter(([uid, vote]) => !vote.tentative && vote.status)
      .map(([uid, vote]) => users.data[uid].name)
      .join(', ')
  );

  if (tally > 0)
    return `${tally} for (${usersInFavor})`;
  else
    return `${tally} for`;
}

export function votesAgainst(votes, users) {
  const tally = nays(votes);
  const usersAgainst = (
    Object.entries(votes || {})
      .filter(([uid, vote]) => !vote.tentative && !vote.status)
      .map(([uid, vote]) => users.data[uid].name)
      .join(', ')
  );

  if (tally > 0)
    return `${tally} against (${usersAgainst})`;
  else
    return `${tally} against`;
}

export function votesTentative(votes, users) {
  const tally = tentative(votes);
  const usersTentative = (
    Object.entries(votes || {})
      .filter(([uid, vote]) => vote.tentative)
      .map(([uid, vote]) => users.data[uid].name)
      .join(', ')
  );

  if (tally > 0)
    return `${tally} tentative (${usersTentative})`;
  else
    return `${tally} tentative`;
}

export function magnitudeGetter(rap) {
  return (row) => {
    const m = row['m'] && row['m'][rap];
    const merr = row['merr'] && row['merr'][rap];
    if ((m === null) || (m === 0) || (merr === null) || (merr > 1) || (merr === 0)) {
      return { m: null, merr: null };
    }
    return { m, merr };
  };
}

export function isReviewer(user, users) {
  return users.data && users.data[user.uid] && users.data[user.uid].reviewer;
}

export function stackNavigator(stacks, stackIndex, setStackIndex, selectedStackIndices) {
  const viewIndex = (index) => {
    if (!isFinite(index)) {
      setStackIndex(0);
    } else if (index < 0) {
      setStackIndex(stacks.length - index);
    } else if (index >= stacks.length) {
      setStackIndex(index - stacks.length);
    } else {
      setStackIndex(index);
    }
  }
  return {
    view: (stack) => viewIndex(stack.index),
    viewByBasename: (basename) => viewIndex(stacks.findIndex((stack) => stack.basename === basename)),
    viewByDate: (date) => viewIndex(stacks.findIndex((stack) => stack.date === date)),
    viewByObjid: (objid) => viewIndex(stacks.findIndex((stack) => stack.objid === objid)),
    first: () => viewIndex(selectedStackIndices.length ? selectedStackIndices[0] : 0),
    previous: () => {
      if (selectedStackIndices.length) {
        const current = selectedStackIndices.indexOf(stackIndex);
        const previous = (current === 0) ? (selectedStackIndices.length - 1) : (current - 1);
        viewIndex(selectedStackIndices[previous]);
      } else {
        const i = (stackIndex === 0) ? (stacks.length - 1) : (stackIndex - 1);
        viewIndex(i);
      }
    },
    next: () => {
      if (selectedStackIndices.length) {
        const current = selectedStackIndices.indexOf(stackIndex);
        const next = (current === (selectedStackIndices.length - 1)) ? 0 : (current + 1);
        viewIndex(selectedStackIndices[next]);
      } else {
        const i = (stackIndex === (stacks.length - 1)) ? 0 : (stackIndex + 1);
        viewIndex(i);
      }
    },
    last: () => viewIndex(
      selectedStackIndices.length
        ? selectedStackIndices[selectedStackIndices.length - 1]
        : (stacks.length - 1)
    )
  };
}

export function getDesignation(objid, allTargets) {
  const target = allTargets.data.find((target) => (target.objid === parseInt(objid)));
  return target ? target.desg : "Unknown object ID";
}

export function getAllDesignations(objid, allTargets) {
  const target = allTargets.data.find((target) => (target.objid === parseInt(objid)));
  if (target) {
    if (target.altdesgs)
      return [target.desg].concat(target.altdesgs.split(","));
    else
      return [target.desg];
  }
  return ["Unknown object ID"];
}

export function getAltDesignations(objid, allTargets) {
  const target = allTargets.data.find((target) => (target.objid === parseInt(objid)));
  if (target)
    return target.altdesgs ? target.altdesgs.split(",") : [];
  else
    return ["Unknown object ID"];
}

/**
 * 
 * @param {number} angle the angle to convert
 * @param {integer} [secondsPrecision] decimals after the point (default 0)
 * @param {integer} [degreesWidth] zero-pad the degrees place to this width (default no padding)
 * @param {boolean} [alwaysSign] always show the +/- sign (default true)
 * @returns 
 */
export function floatToSexagesimal(angle, secondsPrecision, degreesWidth, alwaysSign) {
  if (isNaN(angle)) {
    return 'Nan';
  } else if (!isFinite(angle)) {
    return 'Infinite';
  }

  alwaysSign = alwaysSign === undefined ? true : alwaysSign;

  if (secondsPrecision === undefined) {
    secondsPrecision = 0;
  }

  const sign = (angle < 0) ? '-' : '+';
  let d = Math.floor(Math.abs(angle));
  let m = Math.floor((Math.abs(angle) - d) * 60);
  let s = ((Math.abs(angle) - d) * 60 - m) * 60;

  const factor = Math.pow(10, secondsPrecision);
  s = Math.round(s * factor) / factor;
  if (s >= 60) {
    s -= 60;
    m += 1;
  }

  if (m >= 60) {
    m -= 60;
    d += 1;
  }

  d = d.toFixed(0);
  m = m.toFixed(0);
  s = s.toFixed(secondsPrecision);

  if (degreesWidth !== undefined) {
    d = '0'.repeat(Math.max(degreesWidth - d.length, 0)) + d;
  }

  m = '0'.repeat(2 - m.length) + m;

  if (secondsPrecision === 0) {
    s = '0'.repeat(2 - s.length) + s;
  } else {
    s = '0'.repeat(2 - s.length + secondsPrecision + 1) + s;
  }

  return (alwaysSign ? sign : (sign === '-' ? sign : "")) + d + ':' + m + ':' + s;
}
