import React from 'react';

import { Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Avatar from './Avatar';
import Date from '../pages/Date';
import Link from './Link';
import Outbursts from '../pages/Outbursts';
import Reviews from '../pages/Reviews';
import Status from '../pages/Status';
import Target from '../pages/Target';

import { encodeParameter, isReviewer } from '../utils';
import { auth, useUsers } from '../services/firebase';
import { useAllTargets, useStatus } from '../services/ztf';

function MenuLink(props) {
  return <Link color="inherit" sx={{
    textDecoration: 'none',
  }} {...props} />
}

export default function Main({ user, apiToken }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const users = useUsers();
  const status = useStatus(apiToken);
  const allTargets = useAllTargets(apiToken);

  const target = searchParams.get("target");
  const date = searchParams.get("date");
  const navigate = useNavigate();

  React.useEffect(() => {
    user.isReviewer = isReviewer(user, users);
  }, [user, users]);

  const commonProps = { allTargets, status, user, users, apiToken };

  if (target) {
    navigate(`${process.env.PUBLIC_URL}/target/${encodeParameter(target)}`);
  } else if (date) {
    navigate(`${process.env.PUBLIC_URL}/date/${date}`);
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const pages = [
    { to: `${process.env.PUBLIC_URL}`, label: "All targets" },
    { to: `${process.env.PUBLIC_URL}/target`, label: "Target" },
    { to: `${process.env.PUBLIC_URL}/date`, label: "Date" },
    { to: `${process.env.PUBLIC_URL}/outbursts`, label: "Outbursts" },
  ];
  if (user.isReviewer)
    pages.push({ to: `${process.env.PUBLIC_URL}/reviews`, label: "Reviews" });

  return (
    <>
      {users.error && <Alert severity="error">{users.error.toString()}</Alert>}
      {users.isSuccess &&
        <Box>
          <AppBar position="static" color="primary" elevation={1}>
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
              >
                ZBrowser2
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center"><MenuLink to={page.to}>{page.label}</MenuLink></Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
              >
                ZBrowser2
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Button
                    key={page.label}
                    onClick={handleCloseNavMenu}
                    sx={{ ml: 4, color: 'inherit', display: 'block' }}
                    component={Link}
                    to={page.to}
                  >
                    {page.label}
                  </Button>
                ))}
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar user={user} users={users} />
                </IconButton>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={() => signOut(auth)}>Sign out</MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Box sx={{ m: 2 }}>
            {(allTargets.isLoading || users.isLoading) && (
              <Box>
                <CircularProgress />
                <Typography variant="body2">Loading targets...</Typography>
              </Box>
            )}
            {users.isSuccess && !users.data && <Alert severity="error">Error loading user data</Alert>}
            {(allTargets.isError || status.isError) && <Alert severity="error">Error loading data sources</Alert>}
            {allTargets.isSuccess && status.isSuccess && allTargets.data && users.data &&
              <Routes>
                <Route path={`${process.env.PUBLIC_URL}`} element={<Status {...commonProps} />} />
                <Route path={`${process.env.PUBLIC_URL}/target`} element={<Target {...commonProps} />} />
                <Route path={`${process.env.PUBLIC_URL}/target/:target`} element={<Target {...commonProps} />} />
                <Route path={`${process.env.PUBLIC_URL}/date`} element={<Date {...commonProps} />} />
                <Route path={`${process.env.PUBLIC_URL}/date/:date`} element={<Date {...commonProps} />} />
                <Route path={`${process.env.PUBLIC_URL}/outbursts`} element={<Outbursts {...commonProps} />} />
                <Route path={`${process.env.PUBLIC_URL}/reviews`} element={<Reviews {...commonProps} />} />
              </Routes>
            }
          </Box>
        </Box>}
    </>
  );
}

