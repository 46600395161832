import React from 'react';

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';

import Messages from '../components/Messages';

import { addDBNote, setBadLightcurve, setReviewed, useTargetNotes, useReviews } from '../services/firebase';

export default function TargetNotes({ allTargets, user, users, objid }) {
  const notes = useTargetNotes(objid, allTargets, user, users);
  const review = useReviews(objid, user, users);

  const addNote = () => {
    const textField = document.getElementById('target-note-text');

    addDBNote(objid, user.uid, textField.value)
      .then(() => textField.value = "")
      .catch((reason) => console.log(reason));
  };

  const messages = [...notes.table, ...review.table];
  return (
    <Box>
      <FormLabel>
        Target notes: Why is this data interesting?  What features do you see?  Why is this data problematic?  Does the target name or ephemeris need to be updated?
      </FormLabel>
      <FormGroup row>
        <TextField name="target-note" id="target-note-text" fullWidth />
        <Button onClick={addNote}>Add note</Button>
        {user.isReviewer && <>
          <Button onClick={() => setBadLightcurve(user.uid, objid, ((notes.userVotedBad === null) || (notes.userVotedBad === false)) ? true : null)}>
            <Badge badgeContent={notes.badTally} color="secondary">
              Bad / Useless
            </Badge>
          </Button>
          <Button onClick={() => setBadLightcurve(user.uid, objid, ((notes.userVotedBad === null) || (notes.userVotedBad === true)) ? false : null)}>
            <Badge badgeContent={notes.goodTally} color="secondary">
              Good
            </Badge>
          </Button>
          <Button onClick={() => setReviewed(user.uid, objid, !review.status)} color="secondary">
            {review.status ? "Revoke review" : "Mark as reviewed for paper I"}
          </Button>
        </>
        }
      </FormGroup>
      <FormLabel>
        Mark this target bad if the data quality is fully compromised by artifacts, stars, etc. Upper-limits or noisy data are not "bad" per se, but if the lightcurve is lacking any good points, then why publish the photometry?
      </FormLabel>
      <Messages dense={true} messages={messages} user={user} users={users} />
    </Box >
  );
}
