import React from 'react';

import Box from '@mui/material/Box';

import Table from '../components/Table';
import TargetButton from '../components/TargetButton';

import { useOutbursts } from '../services/firebase';
import { getDesignation, getAltDesignations } from '../utils';

export default function Outbursts({ allTargets }) {
  const outbursts = useOutbursts();

  React.useEffect(() => {
    document.title = "ZBrowser2 : Outbursts";
  });

  const rows = Object.entries(outbursts.tallies)
    .flatMap(([objid, talliesByDate]) => (
      Object.entries(talliesByDate)
        .map(([date, tally]) => ({
          ...tally,
          objid,
          desg: getDesignation(objid, allTargets),
          alt: getAltDesignations(objid, allTargets).join(", "),
          date,
          score: ((tally.yeas + tally.nays) == 0) ? 0 : tally.yeas / (tally.yeas + tally.nays),
          tentative: tally.tentative,
          disposition: tally.disposition ? "Yes" : "No"
        }))
    ))
    .sort((a, b) => a.desg.localeCompare(b.desg, 'en', { numeric: true, sensitivity: 'base' }));

  return (<Box>
    <Table
      columns={
        [
          {
            field: 'desg',
            label: 'Designation',
            width: 150,
            formatter: (desg) => <TargetButton desg={desg} />
          },
          { field: 'alt', label: "Alternate designations", width: 150 },
          { field: 'objid', label: 'Object ID', numeric: true },
          { field: 'date', label: 'Date', width: 125, date: true, numeric: true },
          { field: 'yeas', label: 'Yeas', numeric: true },
          { field: 'tentative', label: 'Tentative', numeric: true },
          { field: 'nays', label: 'Nays', numeric: true },
          { field: 'score', label: 'Score', numeric: true },
          { field: 'disposition', label: "Outburst" },
        ]
      }
      rows={rows}
      heightOverhead={230}
    />
  </Box>
  );
}