import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function StackNavigation({ stackIndex, selectedStackIndices, stacks, stackNavigation, ...props }) {
  const handleKeyDown = React.useCallback((event) => {
    event = event || window.event;
    const target = event.target || event.srcElement;
    if (!/INPUT|TEXTAREA|SELECT/.test(target.nodeName)) {
      switch (event.key) {
        case 'ArrowLeft':
          stackNavigation.previous();
          break;
        case 'ArrowRight':
          stackNavigation.next();
          break;
        case 'Home':
          stackNavigation.first();
          event.preventDefault();
          break;
        case 'End':
          stackNavigation.last();
          event.preventDefault();
          break;
        default:
      }
    }
  }, [stackNavigation]);

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Container {...props}>
      <Box>
        <FormControl sx={{
          width: '30em',
          marginTop: 4,
          marginBottom: 1
        }}>
          <Autocomplete
            id="image-time-field"
            options={stacks}
            getOptionLabel={(stack) => `${stack.basename} / ${stack.tmtp}`}
            renderInput={(params) => <TextField {...params} label="file basename / T-Tp" fullWidth={true} />}
            onChange={(event, value) => values && stackNavigation.view(value)}
            value={stacks[stackIndex]}
          />
        </FormControl>
      </Box>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<FirstPageIcon />}
        onClick={stackNavigation.first}
        sx={{ marginBottom: 1, marginRight: 1 }}
      >
        First
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<NavigateBeforeIcon />}
        onClick={stackNavigation.previous}
        sx={{ marginBottom: 1, marginRight: 1 }}
      >
        Previous
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        endIcon={<NavigateNextIcon />}
        onClick={stackNavigation.next}
        sx={{ marginBottom: 1, marginRight: 1 }}
      >
        Next
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        endIcon={<LastPageIcon />}
        onClick={stackNavigation.last}
        sx={{ marginBottom: 1, marginRight: 1 }}
      >
        Last
      </Button>
      <Typography variant="body1">
        {
          selectedStackIndices.length
            ? selectedStackIndices.indexOf(stackIndex) + 1
            : stackIndex + 1
        }
        {" of "}
        {
          selectedStackIndices.length
            ? selectedStackIndices.length
            : stacks.length
        }
        {" selected stacks"}
      </Typography>
    </Container>
  );
}
