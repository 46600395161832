import React from 'react';

import { grey } from '@mui/material/colors';

import MuiAvatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

export default function Avatar({ user, users }) {
  const userData = users.data[user.uid];
  return (
    <Tooltip title={userData.name}>
      <MuiAvatar
        alt={userData.name}
        src={`https://gravatar.com/avatar/${userData.gravatar}?d=robohash`}
        sx={{ bgcolor: grey[300] }}
      />
    </Tooltip>
  );
}
