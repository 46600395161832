import React from 'react';

import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';

import { apiOnlineUrl, downloadCutouts } from '../services/ztf';
import { encodeParameter, mpcFormat } from '../utils';

import Link from './Link';


function MPCheckerButton({ stack }) {
  const fracday = (stack.obsjd - 0.5).toFixed(5).slice(-5);
  return (
    <form name="mpchecker-form" method="POST" action="https://minorplanetcenter.net/cgi-bin/mpcheck.cgi" target="_blank" style={{ display: "inline" }}>
      <input type="hidden" name="year" value={stack.date.substring(0, 4)} />
      <input type="hidden" name="month" value={stack.date.substring(5, 7)} />
      <input type="hidden" name="day" value={`${stack.date.substring(8, 10)}.${fracday}`} />
      <input type="hidden" name="which" value="pos" />
      <input type="hidden" name="ra" value={mpcFormat.ra(stack.ra)} />
      <input type="hidden" name="decl" value={mpcFormat.dec(stack.dec)} />
      <input type="hidden" name="TextArea" value="" />
      <input type="hidden" name="radius" value="2" />
      <input type="hidden" name="limit" value="24.0" />
      <input type="hidden" name="oc" value="I41" />
      <input type="hidden" name="sort" value="d" />
      <input type="hidden" name="mot" value="h" />
      <input type="hidden" name="tmot" value="s" />
      <input type="hidden" name="pdes" value="u" />
      <input type="hidden" name="needed" value="f" />
      <input type="hidden" name="ps" value="n" />
      <input type="hidden" name="type" value="p" />
      <Button color="primary" variant="contained" disableElevation sx={{ mr: 2 }} size="small" onClick={() => document.forms['mpchecker-form'].submit()}>MPChecker</Button>
    </form>
  );
}

export default function StackHeader({ target, stack, apiToken, ...props }) {
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [snack, setSnack] = React.useState(null);
  const openMenu = Boolean(menuAnchor);

  const openMenuCallback = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const copyLinkToClipboard = () => {
    const url = `${document.URL}?stack=${stack.basename}`;
    navigator.clipboard.writeText(url)
      .then(() => {
        setSnack({ severity: "success", content: "Link to this stack copied to clipboard" });
      }, function () {
        setSnack({ severity: "info", content: "Link to this stack:" + url });
      });
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack(null);
  };

  const handleDownloadCutouts = (stackid) => {
    setMenuAnchor(null);
    setSnack({ severity: "info", content: `Preparing download for stackID=${stackid}.` });
    downloadCutouts(stackid, apiToken, () => setSnack(null));
  };

  const pos = stack && `${stack.ra}${(stack.dec >= 0) ? '+' : ''}${stack.dec}`;

  return (stack &&
    <Container {...props}>
      <Container sx={{ mb: 1 }}>
        <Button color="secondary" variant="contained" disableElevation sx={{ mr: 2 }} component={Link} to={`${process.env.PUBLIC_URL}/target/${encodeParameter(target)}`}>{target}</Button>
        <Button color="secondary" variant="contained" disableElevation sx={{ mr: 2 }} component={Link} to={`${process.env.PUBLIC_URL}/date/${stack.date}`}>{stack.date}</Button>
      </Container>
      <Container sx={{ mb: 1 }}>
        <Button color="primary" variant="contained" disableElevation sx={{ mr: 2 }} size="small" component="a" href={`https://ssd.jpl.nasa.gov/sbdb.cgi?sstr=${target}`} target="_blank">SBDB</Button>
        <Button color="primary" variant="contained" disableElevation sx={{ mr: 2 }} size="small" component="a" href={`http://www.minorplanetcenter.net/db_search/show_object?object_id=${target}`} target="_blank">MPC</Button>
        <MPCheckerButton stack={stack} />
        <Button color="primary" variant="contained" disableElevation sx={{ mr: 2 }} size="small" component="a" href={`http://ps1images.stsci.edu/cgi-bin/ps1cutouts?pos=${pos}&filter=g&filter=r&filter=i&filetypes=stack&auxiliary=data&size=720&output_size=0&verbose=0&autoscale=99.500000`} target="_blank">PS1</Button>
      </Container>
      <Chip
        label={<b>{stack.basename}</b>}
        color="primary"
        variant="outlined"
        onClick={copyLinkToClipboard}
        sx={{ mr: 2 }}
      />
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        onClick={openMenuCallback}
      >
        Download...
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose} component="a" href={`${apiOnlineUrl}/stacks/${stack.stackfile}`}>Stack</MenuItem>
        <MenuItem onClick={() => handleDownloadCutouts(stack.stackid)}>Cutouts</MenuItem>
      </Menu>
      <Snackbar open={Boolean(snack)} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={snack ? snack.severity : "success"} sx={{ width: '100%' }}>
          {snack ? snack.content : ""}
        </Alert>
      </Snackbar>
    </Container>
  );
}