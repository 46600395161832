import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';

export default function SelectNight({ sx, status }) {
  const navigate = useNavigate();
  const { date } = useParams();
  const mostRecentNight = status.data['most recent night checked'];

  const openDate = (newDate) => {
    // require a valid ZTF date.
    if (newDate >= '2017-10-15')
      navigate(`${process.env.PUBLIC_URL}/date/${newDate}`);
  };

  return (
    <Box sx={sx}>
      <Input
        id="date"
        label="Night"
        type="date"
        value={date}
        sx={{ width: 220 }}
        onChange={(event) => openDate(event.target.value)}
      />
      <Button color="primary" disabled={!mostRecentNight} onClick={() => openDate(mostRecentNight)}>Most recent night</Button>
    </Box>
  );
}
