import React from 'react';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Messages from './Messages';

import { addDBNote, setOutburst, useStackNotesByTarget } from '../services/firebase';

export default function StackNotesByTarget({ allTargets, objid, user, users, stack, stackNavigation, outbursts }) {
  const messages = useStackNotesByTarget(allTargets, objid, users)
    .map((message) => ({
      ...message,
      action: () => {
        if (message.note) {
          stackNavigation.viewByBasename(message.note.basename);
        } else {
          stackNavigation.viewByDate(message.date);
        }
      },
      selected: stack.date === message.date
    }));

  const thisStacksMessages = messages
    .filter((message) =>
      (message.note && message.note.basename === stack.basename)
      || (!message.note && message.date === stack.date)
    )
    .map((message) => ({ ...message, selected: false, action: null }));

  const tally = (stack && outbursts.tallies[stack.date]) || {};

  const addNote = () => {
    const textfield = document.getElementById('stack-note-text');

    addDBNote(stack.objid, user.uid, textfield.value, stack.basename, stack.date)
      .then(() => textfield.value = "")
      .catch((reason) => console.log(reason));
  };

  return (<>
    <Box sx={{ my: 2 }}>
      <Typography variant="h6">Stack notes</Typography>
      <Messages messages={thisStacksMessages} user={user} users={users} />
      <FormLabel>What makes this stack interesting?  Why is this stack bad (if not obvious)?  For outbursts, mark the first night the event is observed.</FormLabel>
      <FormGroup row={true}>
        <TextField name="stack-note" id="stack-note-text" fullWidth />
        <Button onClick={addNote}>Add note</Button>
        <Button onClick={() => setOutburst(user.uid, stack.objid, stack.date, true, false)}>
          <Badge badgeContent={tally.yeas} color="secondary">
            Outburst!
          </Badge>
        </Button>
        <Button onClick={() => setOutburst(user.uid, stack.objid, stack.date, false, true)}>
          <Badge badgeContent={tally.tentative} color="secondary">
            Tentative outburst
          </Badge>
        </Button>
        <Button onClick={() => setOutburst(user.uid, stack.objid, stack.date, false, false)}>
          <Badge badgeContent={tally.nays} color="secondary">
            Not an outburst
          </Badge>
        </Button>
      </FormGroup>
    </Box>
    <Box>
      <Typography variant="h6">All stack notes</Typography>
      <Messages dense={true} messages={messages.sort((a, b) => a.date.localeCompare(b.date))} user={user} users={users} />
    </Box>
  </>);
}