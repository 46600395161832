import React from 'react';
import { VariableSizeGrid as Grid } from 'react-window';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import visuallyHidden from '@mui/utils/visuallyHidden';

function getColumnSorter(column, order) {
  if (column === null) {
    return (a, b) => 0;
  } else {
    return (a, b) => {
      let A = a[column.field];
      let B = b[column.field];

      const sign = order === 'desc' ? -1 : 1;

      if (A === B)
        return 0;

      // nulls should always be last
      if (A === null)
        return 1;

      if (B === null)
        return -1;

      if (column.numeric) {
        A = Number(A);
        B = Number(B);
      } else if (column.date) {
        A = new Date(A);
        B = new Date(B);
      } else {
        // ignore case
        A = String(A).toLowerCase().trim();
        B = String(B).toLowerCase().trim();

        ['the ', 'a ', '('].forEach(prefix => {
          A = A.startsWith(prefix) ? A.slice(prefix.length) : A;
          B = B.startsWith(prefix) ? B.slice(prefix.length) : B;
        });

        return sign * A.localeCompare(B, undefined, { numeric: true, sensitivity: "base" });
      }

      if (A < B) {
        return -sign;
      }
      if (A > B) {
        return sign;
      }

      // equal
      return 0;
    }
  }
}

const HeaderCell = ({ columnIndex, rowIndex, style, data }) => (
  <div style={{ ...style, borderBottom: '1px solid #aaa' }}>
    <TableCell variant="head" sx={{ border: "none" }} padding="none" align="center" component="div">
      {data[rowIndex][columnIndex]}
    </TableCell>
  </div >
);

const DataCell = ({ columnIndex, rowIndex, style, data }) => (
  <div style={{ ...style, borderBottom: '1px solid #aaa' }}>
    <TableCell sx={{ border: "none" }} padding="none" component="div">
      {data[rowIndex][columnIndex]}
    </TableCell>
  </div>
);

const Cell = (props) => {
  return (
    (props.rowIndex === 0) ? <HeaderCell {...props} /> : <DataCell {...props} />
  )
};

function rowContains(row, searchStrings) {
  if (searchStrings.length === 0) {
    return true;
  }
  const rowString = row.join(" ").toLowerCase();
  const search = searchStrings.map(s => rowString.includes(s.toLowerCase())).filter(test => test);
  return search.length == searchStrings.length;
}

function formatDate(date) {
  if ([undefined, null, ""].includes(date))
    return "";

  const year = date.substring(0, 4);
  const month = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
  }[date.substring(5, 7)];
  const andTheRest = date.substring(8);
  return `${year} ${month} ${andTheRest}`;
}

function intersection(setA, setB) {
  let _intersection = new Set()
  for (let elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem)
    }
  }
  return _intersection
}

export default function Table({ columns, rows, tags, taggedBy, heightOverhead }) {
  const ref = React.useRef(null);
  const [width, setWidth] = React.useState(1000);
  const [height, setHeight] = React.useState(500);
  const [searchStrings, setSearchStrings] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState(null);
  const [order, setOrder] = React.useState('asc');
  const [filterByTag, setFilterByTag] = React.useState([]);

  const search = (event) => {
    setSearchStrings(event.target.value.split(/\s+/));
  }

  const handleRequestSort = (column) => {
    // Sequence: Order ascending, order descending, unsorted
    const isAsc = column === orderBy && order === 'asc';
    const isDesc = column === orderBy && order === 'desc';
    if (isDesc) {
      setOrderBy(null);
    } else {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(column);
    }
  };

  const sortedRows = [...rows].sort(getColumnSorter(orderBy, order));
  const mappedRows = sortedRows.map(row => columns.map(column => {
    const cell = row[column.field];
    if (column.date)
      return formatDate(cell);
    else
      return cell;
  }));
  const formattedRows = mappedRows.map((row, i) => row.map((cell, index) =>
    columns[index].formatter
      ? columns[index].formatter(cell, sortedRows[i])
      : cell
  ));
  const filteredRows = formattedRows
    .filter((row, i) => {
      let test = false;
      if (filterByTag.length === 0) {
        test = true;
      } else {
        test = filterByTag
          .map((tag) => tags[tag].has(sortedRows[i][taggedBy]))
          .reduce((a, b) => a && b);
      }
      test = test && rowContains(mappedRows[i], searchStrings);
      return test;
    });

  const itemData = [
    columns.map(column =>
      // decorate column headings with sort annotations
      <TableSortLabel
        active={orderBy === column}
        direction={orderBy === column ? order : 'asc'}
        onClick={() => handleRequestSort(column)}
      >
        {column.label}
        {orderBy === column ? (
          <Box component="span" sx={visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
    ),
    ...filteredRows
  ];

  const handleWindowResize = () => {
    setWidth(ref.current.offsetWidth);
    setHeight(window.innerHeight - (heightOverhead || 200));
  }

  const handleChangeTagFilter = (tag) => {
    if (filterByTag.includes(tag))
      setFilterByTag(filterByTag.filter((t) => t !== tag));
    else
      setFilterByTag([...filterByTag, tag]);
  }

  React.useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Box>
      <TextField
        label="Search"
        variant="outlined"
        onChange={search}
        sx={{ my: 2 }}
        helperText="Matches unformatted data columns, case insensitive"
      />
      {tags &&
        <FormGroup row={true} sx={{ mb: 2 }}>
          {Object.keys(tags).map((tag) =>
            <FormControlLabel
              key={tag}
              label={tag}
              control={
                <Checkbox
                  disabled={tags[tag].size === 0}
                  checked={filterByTag.includes(tag)}
                  onChange={() => handleChangeTagFilter(tag)}
                />
              }
            />
          )}
        </FormGroup>
      }
      <Typography variant="body2" gutterBottom>
        {((itemData.length - 1) === rows.length)
          ? `${itemData.length - 1} rows`
          : `Search matches ${itemData.length - 1} of ${rows.length} rows`
        }

      </Typography>
      <div ref={ref}>
        <Grid
          columnCount={columns.length}
          columnWidth={index => columns[index].width || 75}
          height={height}
          rowCount={itemData.length}
          rowHeight={index => (index === 0 ? 70 : 35)}
          width={width}
          itemData={itemData}
        >
          {Cell}
        </Grid>
      </div>
    </Box>
  );
}