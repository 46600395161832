import React from "react";

function readCookie(scope, key, defaultValue) {
  const storage = (scope === 'session') ? window.sessionStorage : window.localStorage;

  let decode;
  if (typeof defaultValue === 'boolean') {
    decode = (s) => s === 'true';
  } else if (typeof defaultValue === 'number') {
    decode = (s) => Number(s);
  } else if (typeof defaultValue === 'object') {
    // save as JSON
    decode = JSON.parse;
  } else {
    decode = (s) => s;
  }

  const stored = storage.getItem(`zbrowser2-${key}`);
  const value = stored ? decode(stored) : defaultValue;

  return value;
}

function storeCookie(scope, key, value) {
  const storage = (scope === 'session') ? window.sessionStorage : window.localStorage;

  let encode;
  if (typeof value === 'boolean') {
    encode = String;
  } else if (typeof value === 'number') {
    encode = String;
  } else if (typeof value === 'object') {
    // save as JSON
    encode = JSON.stringify;
  } else {
    encode = (value) => value;
  }

  storage.setItem(`zbrowser2-${key}`, encode(value));
}

export function useCookieState(scope, key, defaultValue) {
  const [state, _setState] = React.useState(readCookie(scope, key, defaultValue));

  const setState = (value) => {
    storeCookie(scope, key, value);
    _setState(value);
  }

  return [state, setState];
}

export function useLayout() {
  return useCookieState("local", "layout", "horizontal");
}

export function useLightcurveConfig(target, defaultConfig) {
  const [config, setConfig] = useCookieState("session", `lightcurve-config-${target}`, defaultConfig);

  React.useEffect(() => {
    setConfig(readCookie("session", `lightcurve-config-${target}`, defaultConfig));
  }, [target]);

  return [config, setConfig];
}

export function useStackIndex(key) {
  // key may be a date or target
  const [stackIndex, setStackIndex] = useCookieState("session", `stack-index-${key}`, 0);
  React.useEffect(() => {
    setStackIndex(readCookie("session", `stack-index-${key}`, 0));
  });
  return [stackIndex, setStackIndex];
}