import { useQuery } from "react-query";

export const apiOnlineUrl = 'https://www.astro.umd.edu/~msk/zbrowser2';
export const apiLocalUrl = 'http://localhost:8000';

export function apiBaseUrl() {
  if (location.hostname === "localhost")
    return apiLocalUrl;
  else
    return apiOnlineUrl;
}

async function fetchFromAPI(target, apiToken) {
  return await fetch(
    `${apiBaseUrl()}/${target}`,
    {
      credentials: 'include',
      headers: {
        'Authorization': `Token token="${apiToken.data}"`
      }
    }
  );
}

async function fetchAllTargets(apiToken) {
  console.log('fetch all target summary');
  const response = await fetchFromAPI("target-summary.php", apiToken);
  if (!response.ok) {
    console.log('Error fetching target summary');
    throw new Error('Error fetching target summary');
  }
  return response.json()
}

async function fetchTargetObservations(target, apiToken) {
  console.log('fetch target data', target);
  if (!target) {
    return new Promise(() => ({ valid: false, objid: null, data: [] }));
  }

  const response = await fetchFromAPI(`obs-by-target.php?target=${target}`, apiToken);
  if (!response.ok) {
    throw new Error(`Error fetching observations for ${target}`);
  }
  return response.json();
}

async function fetchNightlySummary(date, apiToken) {
  console.log('fetch nightly summary', date);
  if (!date) {
    return new Promise(() => ({ valid: false, date: null, night: {}, table: [], stacks: [] }));
  }
  const response = await fetchFromAPI(`obs-by-date.php?date=${date}`, apiToken);
  if (!response.ok) {
    throw new Error(`Error fetching observations for ${date}`);
  }
  return response.json()
}

async function fetchStatus(apiToken) {
  console.log('fetch status');
  const response = await fetchFromAPI("status.php", apiToken);
  if (!response.ok) {
    throw new Error(`Error fetching status`);
  }
  return response.json()
}

export function useAllTargets(apiToken) {
  return useQuery('target-summary', () => fetchAllTargets(apiToken), { staleTime: 3000000, retry: false });
}

export function useTargetPhotometry(target, apiToken) {
  return useQuery(['obs-by-target', target], () => fetchTargetObservations(target, apiToken),
    { staleTime: 3000000, retry: false, placeholderData: { table: [], stacks: [] } });
}

export function useNightlySummary(date, apiToken) {
  return useQuery(
    ['obs-by-date', date],
    () => fetchNightlySummary(date, apiToken),
    {
      staleTime: 3000000,
      retry: false,
      placeholderData: {
        valid: false,
        date,
        night: {},
        stacks: [],
        table: []
      }
    }
  );
}

export function useStatus(apiToken) {
  return useQuery('status', () => fetchStatus(apiToken), { staleTime: 3000000, retry: false });
}

export function downloadCutouts(stackid, apiToken, callBack) {
  if (stackid) {
    let filename = "";
    let contentType = ""
    fetchFromAPI(`download-cutouts.php?stackid=${stackid}`, apiToken)
      .then((response) => {
        const content = response.headers.get('Content-Disposition');
        const parts = content.split(';');
        filename = parts[1].split('=')[1].slice(1, -1);
        contentType = response.headers.get('Content-Type');
        return response.blob()
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        link.setAttribute('type', contentType);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .then(callBack)
      .catch(() => console.log(`Error fetching cutouts`));
  }
}