import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FooterButton from '../components/FooterButton';
import Messages from '../components/Messages';
import SelectNight from '../components/SelectNight';
import SelectTarget from '../components/SelectTarget';
import Table from '../components/Table';
import TargetButton from '../components/TargetButton';

import { useAllNotesTable } from '../services/firebase';

export default function Status({ allTargets, status, user, users }) {
  const navigate = useNavigate();
  const { allNotesTable, loading, error } = useAllNotesTable(allTargets, users, navigate);

  React.useEffect(() => {
    document.title = "ZBrowser2";
  }, []);

  return (
    <>
      {loading && (
        <Box>
          <CircularProgress />
          <Typography variant="body2">Loading targets...</Typography>
        </Box>
      )}
      <Stack direction="row" alignItems="center" spacing={6}>
        <SelectTarget allTargets={allTargets} />
        <SelectNight status={status} />
      </Stack>
      <Grid container sx={{ mb: 4 }}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <List subheader={`${status.data['targets'] || "..."} targets in database`} sx={{ mt: 2 }}>
            <ListItem>
              <ListItemText primary={status.data['targets with coverage'] || "..."} secondary="Targets with ZTF coverage" />
            </ListItem>
            <ListItem>
              <ListItemText primary={status.data['most recent targets'] || "..."} secondary="Covered on most recent night" />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <List subheader={`${status.data['nights'] || "..."} nights in database`} sx={{ mt: 2 }}>
            <ListItem>
              <ListItemText primary={status.data['nights with data'] || "..."} secondary="Nights with data" />
            </ListItem>
            <ListItem>
              <ListItemText primary={status.data['most recent night checked'] || "..."} secondary="Most recent night checked" />
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <FooterButton title="All targets">
        <Table
          sx={{ height: 'auto' }}
          columns={
            [
              { field: 'objid', label: 'Object ID', width: 75, numeric: true },
              {
                field: 'desg',
                label: 'Designation',
                width: 150,
                formatter: (desg, row) => <TargetButton desg={desg} />
              },
              { field: "first_ephemeris_update", label: "Oldest ephemeris retrieved", width: 100, },
              { field: "last_ephemeris_update", label: "Latest ephemeris retrieved", width: 100, },
              { field: 'nnights', label: 'N(nights)', width: 75, numeric: true },
              { field: "first_night_covered", label: "First night covered", width: 100, },
              { field: "last_night_covered", label: "Last night covered", width: 100, },
              { field: 'nobs', label: 'N(obs)', width: 75, numeric: true },
              { field: "first_night_detected", label: "First night detected", width: 100, },
              { field: "last_night_detected", label: "Last night detected", width: 100, },
              { field: 'ndet', label: 'N(detections)', width: 100, numeric: true },
              { field: 'ng', label: 'N(g)', width: 75, numeric: true },
              { field: 'nr', label: 'N(r)', width: 75, numeric: true },
              { field: 'ni', label: 'N(i)', width: 75, numeric: true },
            ]
          }
          rows={allTargets.data}
        />
      </FooterButton>

      <Typography variant="h6" gutterBottom>Target notes</Typography>
      <Messages
        dense={true}
        messages={Array.prototype.concat(...allNotesTable.map(row => row.notes)).sort((a, b) => (b.timestamp - a.timestamp))}
        user={user}
        users={users}
      />
      {/* <Table
        columns={
          [
            {
              field: 'desg',
              label: 'Designation',
              width: 150,
              formatter: (desg) =>
                <Button
                  color="primary"
                  component={Link}
                  to={`${process.env.PUBLIC_URL}/target/${encodeParameter(desg)}`}
                >
                  {desg}
                </Button>
            },
            {
              field: 'notes',
              label: 'Notes',
              width: 800,
              formatter: (notes) => <Messages dense={true} messages={notes} user={user} users={users} />
            }
          ]
        }
        rows={allNotesTable}
      /> */}

    </>
  );
}
