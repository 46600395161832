import React from 'react';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Fab from '@mui/material/Fab';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import TableViewIcon from '@mui/icons-material/TableView';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledFab = styled(Fab)({
  position: 'fixed',
  zIndex: 1,
  bottom: 16,
  right: 16,
});

export default function FooterButton({ title, icon, children, download, sx, color }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={title || "Table"}>
        <StyledFab
          color={color || "secondary"}
          onClick={handleClickOpen} sx={sx}
        >
          {icon || <TableViewIcon />}
        </StyledFab>
      </Tooltip>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title || "Table"}
            </Typography>
            {download &&
              <Button autoFocus color="inherit" component="a" href={download}>
                save
              </Button>
            }
          </Toolbar>
        </AppBar>
        <Container maxWidth={false} sx={{ mt: 2 }}>
          {children}
        </Container>
      </Dialog>
    </div>
  );
}
