import React from 'react';
import * as firebase from "firebase/app";
import Typography from '@mui/material/Typography';
import { getDatabase, connectDatabaseEmulator, ref, get, set, push, update } from 'firebase/database';
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { useObjectVal, useList } from 'react-firebase-hooks/database';
import { encodeParameter, yeas, nays, outburstTally, getDesignation } from '../utils';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const database = getDatabase(firebaseApp);
export const auth = getAuth(firebaseApp);
setPersistence(auth, browserLocalPersistence);

if (location.hostname === "localhost") {
  // Point to the RTDB emulator running on localhost.
  console.log('Connecting to localhost RTDB.')
  connectDatabaseEmulator(database, "localhost", 9000);
}

export function fromFirebase(key, useFunction) {
  const [data, loading, error] = useFunction(ref(database, key));
  return {
    data: data,
    isLoading: loading,
    error: error,
    isSuccess: !loading && !error
  };
}

/**
 * 
 * @param {Object} users Users database object
 * @param {string} pushid Note unique database identifier
 * @param {Object} note Database note object
 * @param {string} [desg] Annotate the message with this target's designation.
 * @returns 
 */
function noteToTargetMessage(users, pushid, note, desg) {
  const date = new Date();
  date.setTime(note.timestamp);
  const text = desg
    ? <>
      <Typography variant="body1" gutterBottom>{desg}</Typography>
      <Typography variant="body2">{note.text}</Typography>
    </>
    : note.text;
  return {
    uid: note.uid,
    timestamp: note.timestamp,
    text: text,
    secondaryText: `${users.data[note.uid].name} / ${date.toUTCString()}`,
    ref: ref(database, `notes/targets/${note.objid}/${pushid}`)
  };
}

/**
 * 
 * @param {Object} users Users database object
 * @param {string} pushid Note unique database identifier
 * @param {Object} note Database note object
 * @returns 
 */
function noteToStackMessage(users, pushid, note) {
  const date = new Date();
  date.setTime(note.timestamp);
  const text = <>
    <Typography variant="body1">{note.text}</Typography>
    <Typography variant="body2" gutterBottom>{note.basename}</Typography>
  </>;
  return {
    uid: note.uid,
    text,
    secondaryText: `${users.data[note.uid].name} / ${date.toUTCString()}`,
    ref: ref(database, `notes/targets/${note.objid}/${pushid}`),
    note,
    objid: parseInt(note.objid),
    date: note.obsdate
  };
}

function outburstToStackMessage(allTargets, objid, date, votes, tally) {
  const desg = getDesignation(objid, allTargets);
  const disposition = ((tally.yeas >= tally.nays) && (tally.yeas >= tally.tentative))
    ? "Outburst"
    : ((tally.tentative >= tally.nays) ? "Tenative outburst" : "Not an outburst");
  const text = <>
    <Typography variant="body1">{disposition}</Typography>
    <Typography variant="body2" gutterBottom>{desg}, {date}</Typography>
  </>;
  return {
    uid: Object.keys(votes),
    text,
    secondaryText: `${tally.votesFor}, ${tally.votesTentative}, ${tally.votesAgainst}`,
    objid: parseInt(objid),
    date,
    votes
  };
}

export function useAllNotesTable(allTargets, users, navigate) {
  const [data, loading, error] = useObjectVal(ref(database, "notes/targets"));
  const table = Object.entries(!loading && !error && data || {})
    .sort((a, b) => (a[1].timestamp < b[1].timestamp))
    .map(([objid, notes]) => {
      const desg = getDesignation(objid, allTargets);
      return {
        desg,
        notes: Object.entries(notes)
          .filter(([pushid, note]) => !note.basename)
          .map(([pushid, note]) => ({
            ...noteToTargetMessage(users, pushid, note, desg),
            action: () => navigate(`${process.env.PUBLIC_URL}/target/${encodeParameter(desg)}`)
          }))
      };
    })
    .filter((row) => row.notes.length > 0)
    .sort((a, b) => a.desg.localeCompare(b.desg, 'en', { numeric: true, sensitivity: 'base' }));

  return { allNotesTable: table, loading, error };
}

function badToMessage(users, uid, bad) {
  const date = new Date();
  date.setTime(bad.timestamp);
  return {
    uid: uid,
    text: bad.status && "Lightcurve is bad or useless" || "Lightcurve is good",
    secondaryText: `${users.data[uid].name} / ${date.toUTCString()}`
  };
}

export function useTargetNotes(objid, allTargets, user, users) {
  const [data, loading, error] = useList(ref(database, `notes/targets/${objid}`));
  const [bads, badsLoading, badsError] = useObjectVal(ref(database, `badlightcurves/${objid}`));
  const badTally = yeas(bads || {});
  const goodTally = nays(bads || {});
  const userVotedBad = bads && bads[user.uid] && bads[user.uid].status;

  if (objid === undefined)
    return { table: [], badTally: 0, goodTally: 0 };

  const targets = allTargets.data || {};
  const firstEphemerisUpdate = (targets.find(row => row.objid === objid) || { first_ephemeris_update: '<objid not found>' }).first_ephemeris_update;

  const table = Array.prototype.concat(
    [{
      uid: null,
      text: `Oldest ephemeris data was retrieved on ${firstEphemerisUpdate}`
    }],
    (data || [])
      .filter((snap) => !snap.val().basename)
      .map((snap) => noteToTargetMessage(users, snap.key, snap.val())),
    Object.entries(bads || {}).map(([uid, bad]) => badToMessage(users, uid, bad))
  );

  return {
    table,
    badTally,
    goodTally,
    userVotedBad: (userVotedBad === undefined) ? null : userVotedBad
  };
}

export function useReviews(objid, user, users) {
  const [data, loading, error] = useObjectVal(ref(database, `reviews/targets/${objid}`));
  // number of users who have reviewed this data
  const nReviewed = Object.values(data || {}).reduce((total, review) => (total + review.status), 0);
  const status = ((data || {})[user.uid] || { status: false }).status;
  const table = Object.entries(data || {})
    .filter(([key, review]) => review.status)
    .map(([key, review]) => {
      const date = new Date();
      date.setTime(review.timestamp);
      return {
        uid: key,
        text: `Reviewed by ${users.data[key].name}`,
        secondaryText: date.toUTCString()
      }
    });
  return {
    data: (data || {}),
    status,
    nReviewed,
    table
  };
}

export function useAllReviews(users, assignments, allTargets) {
  const [data, loading, error] = useObjectVal(ref(database, `reviews/users`));
  const reviews = data || {};

  if (Object.keys(reviews).length === 0)
    return { reviews: {}, userTotals: [], messages: [], reviewTables: {} };

  const userTotals = Object.entries(reviews)
    .map(([uid, reviews]) => ({
      uid: uid,
      value: Object.values(reviews).reduce((total, current) => (total + current.status), 0)
    }));

  const messages = userTotals.map((total) => ({
    uid: total.uid,
    text: `${users.data[total.uid].name} has reviewed ${total.value} target${(total.value == 1) ? "" : "s"}`
  }));

  const reviewedObjIds = new Set(Object.values(reviews).map(
    user => Object.entries(user)
      .filter(([objid, review]) => review.status)
      .map(([objid, review]) => objid)
  ).flat(1))
  const reviewedTargets = allTargets.data.filter((target) => reviewedObjIds.has(String(target.objid)));

  const reviewTables = Object.fromEntries(
    Object.entries(users.data)
      .map(([uid, user]) => {
        const targets = allTargets.data.filter((target) =>
          Object.keys(reviews[uid] || {}).includes(String(target.objid))
          || (assignments[user.name] || []).includes(target.objid));

        const table = targets.map((target) => {
          const review = (reviews[uid] || {})[target.objid] || { status: false };
          if (review.timestamp) {
            const date = new Date();
            date.setTime(review.timestamp);
            review.date = date.toISOString().substring(0, 10);
          } else {
            review.date = "";
          }

          const assigned = (assignments[user.name] || []).includes(target.objid);

          return {
            ...target,
            reviewed: review.status ? "Reviewed" : (assigned ? "" : "Revoked"),
            date: review.date,
            assigned: (assigned
              ? (review.status ? "Complete" : "Assigned")
              : "")
          };
        });

        return [uid, table];
      })
  );

  return { reviews, userTotals, messages, reviewTables, reviewedTargets };
}

export function useStackNotesByTarget(allTargets, objid, users) {
  const [notes, loading, error] = useList(ref(database, `notes/targets/${objid}`));
  const outbursts = useOutburstsByTarget(objid, users);

  if (objid === undefined)
    return [];

  if (loading || error)
    return [];

  const messages = Array.prototype.concat(
    (notes || [])
      .filter((snap) => snap.val().basename)
      .map((snap) => noteToStackMessage(users, snap.key, snap.val())),
    Object.entries(outbursts.data).map(([date, votes]) =>
      outburstToStackMessage(allTargets, objid, date, votes, outbursts.tallies[date]))
  );

  return messages;
}

export function useStackNotesByDate(allTargets, date, users) {
  const [notes, loading, error] = useList(ref(database, `notes/dates/${date}`));
  const outbursts = useOutburstsByDate(date, users);

  if (date === undefined)
    return [];

  if (loading || error)
    return [];

  const messages = Array.prototype.concat(
    (notes || [])
      .filter((snap) => snap.val().basename)
      .map((snap) => noteToStackMessage(users, snap.key, snap.val())),
    Object.entries(outbursts.data).map(([objid, votes]) =>
      outburstToStackMessage(allTargets, objid, date, votes, outbursts.tallies[objid]))
  );

  return messages;
}

export function useOutbursts() {
  const [data, loading, error] = useObjectVal(ref(database, "outbursts/targets"));
  const tallies = Object.fromEntries(
    Object.entries(data || {})
      .map(([objid, votesByDate]) => {
        const talliesByDate = Object.fromEntries(
          Object.entries(votesByDate)
            .map(([date, votes]) => ([date, outburstTally(votes)]))
        );
        return [objid, talliesByDate];
      })
  );
  return {
    data: data || {},
    tallies
  };
}

export function useOutburstsByTarget(objid, users) {
  const [data, loading, error] = useObjectVal(ref(database, `outbursts/targets/${objid}`));
  const tallies = Object.fromEntries(
    Object.entries(data || {})
      .map(([date, votes]) => (
        [date, outburstTally(votes, users)]
      ))
  );
  const confirmedDates = new Set(
    Object.entries(tallies)
      .filter(([date, tally]) => tally.disposition)
      .map(([date, tally]) => date)
  );
  return {
    data: data || {},
    tallies,
    confirmedDates
  };
}

export function useOutburstsByDate(date, users) {
  const [data, loading, error] = useObjectVal(ref(database, `outbursts/dates/${date}`));
  const tallies = Object.fromEntries(
    Object.entries(data || {})
      .map(([date, votes]) => (
        [date, outburstTally(votes, users)]
      ))
  );
  const confirmedTargets = new Set(
    Object.entries(tallies)
      .filter(([objid, tally]) => tally.disposition)
      .map(([objid, tally]) => objid)
  );
  return {
    data: data || {},
    tallies,
    confirmedTargets
  };
}

export function useBadPhotometry(objid) {
  const [data, loading, error] = useObjectVal(ref(database, `badphotometry/${objid}`));
  return data || [];
}

export function useUsers() {
  const [data, loading, error] = useObjectVal(ref(database, 'users'));
  return {
    data: data,
    isLoading: loading,
    error: error,
    isSuccess: !loading && !error
  };
}

/**
 * @param {number} objid object ID
 * @param {string} uid user ID
 * @param {string} text note text
 * @param {number} [basename] stack file base name (requires obsdate)
 * @param {string} [obsdate] YYYY-MM-DD (requires basename)
 * @returns {Promise}
 */
export function addDBNote(objid, uid, text, basename, obsdate) {
  // validate data
  if (!isFinite(parseInt(objid)))
    return Promise.reject(`invalid objid ${objid}`);

  if (!basename !== !obsdate)
    return Promise.reject("basename and obsdate are mutually required")

  if (obsdate && (obsdate.length != 10))
    return Promise.reject(`invalid obsdate: ${obsdate}`)

  // compose the note
  const note = {
    uid: uid,
    text: text,
    timestamp: new Date().getTime(),
    objid: objid
  };

  // optional parameters, but mutually required
  if (basename) {
    note.basename = basename;
    note.obsdate = obsdate;
  }

  // Get a key for a new note.
  const pushId = push(ref(database, `notes/targets/${objid}`)).key;

  if (note.uid && note.text && (note.text.length > 0)) {
    // Write the note to two locations
    const notes = {}
    notes[`notes/targets/${objid}/${pushId}`] = note;
    if (obsdate) {  // if obsdate defined, then save it there:
      notes[`notes/dates/${obsdate}/${pushId}`] = note;
    }
    return update(ref(database), notes);
  } else {
    return Promise.reject('uid and text are required');
  }
}

/** Delete a database note.
 * @param {DatabaseReference} ref database reference to note
 */
export function deleteDBNote(noteRef) {
  // the the note data
  get(noteRef)
    .then((snap) => {
      // to reconstruct the note location, we need objid, obsdate (if available)
      // and the pushid
      const { objid, obsdate } = snap.val();
      const pushid = snap.key;

      const paths = {};
      // there is always a note under targets, delete it
      paths[`/notes/targets/${objid}/${pushid}`] = null;

      // also delete note sorted under the observation date, if obsdate is available
      if (obsdate) {
        paths[`/notes/dates/${obsdate}/${pushid}`] = null;
      }

      return update(ref(database), paths);
    })
    .catch((reason) => console.log(reason));
}

/**
 * Vote for an outburst
 * @param {string} uid user ID
 * @param {number} objid object ID
 * @param {string} obsdate YYYY-MM-DD
 * @param {bool} status outburst or not?
 * @param {bool} tentative tentative outburst?
 * @returns {Promise}
 */
export function setOutburst(uid, objid, obsdate, status, tentative) {
  if (status && tentative) {
    throw Error("Cannot simultaneously set outburst as real and tentative.")
  }
  const vote = {
    timestamp: new Date().getTime(),
    status: status,
    tentative: tentative
  }
  const values = {}
  values[`outbursts/targets/${objid}/${obsdate}/${uid}`] = vote;
  values[`outbursts/dates/${obsdate}/${objid}/${uid}`] = vote;
  return update(ref(database), values);
}

/**
 * Mark object as reviewed
 * @param {string} uid user ID
 * @param {number} objid object ID
  * @param {bool} status reviewed or not?
 * @returns {Promise}
 */
export function setReviewed(uid, objid, status) {
  const review = {
    timestamp: new Date().getTime(),
    status: status
  }
  const values = {}
  values[`reviews/users/${uid}/${objid}`] = review;
  values[`reviews/targets/${objid}/${uid}`] = review;
  return update(ref(database), values);
}

/**
 * Mask multiple product ID's photometry
 * @param {string} uid user ID
 * @param {number} objid object ID
 * @param {Array} pids product ID
 * @param {bool} status mask or not?
 * @returns {Promise}
 */
export function maskPIDs(uid, objid, pids, status) {
  const values = Object.fromEntries(pids.map((pid) => ([
    `badphotometry/${objid}/${pid}/${uid}`,
    {
      timestamp: new Date().getTime(),
      status: status
    }
  ])));
  return update(ref(database), values);
}

/**
 * Set bad status for a lightcurve
 * @param {string} uid user ID
 * @param {number} objid object ID
 * @param {bool} status true to bad, false for good, null for remove vote
 * @returns {Promise}
 */
export function setBadLightcurve(uid, objid, status) {
  const vote = status === null
    ? null
    : {
      timestamp: new Date().getTime(),
      status: status
    };
  return set(ref(database, `badlightcurves/${objid}/${uid}`), vote);
}

export function useApiToken() {
  const [data, loading, error] = useObjectVal(ref(database, "apikey"));
  return { data, loading, error };
}

export default firebaseApp;
