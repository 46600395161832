import React from 'react';
import Button from '@mui/material/Button';
import Link from '../components/Link';
import { encodeParameter } from '../utils';

export default function TargetButton({ desg, ...props }) {
  return (
    <Button
      color="primary"
      component={Link}
      to={`${process.env.PUBLIC_URL}/target/${encodeParameter(desg)}`}
      {...props}
    >
      {desg}
    </Button>
  );
}