import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const annotations = {
  'date': { label: 'Date', unit: "", color: "success" },
  'tmtp': { label: 'T-Tp', unit: " d", color: "success" },
  'rh': { label: 'rh', unit: " au", color: "success" },
  'delta': { label: 'Delta', unit: " au", color: "success" },
  'phase': { label: 'Phase', unit: "°", color: "success" },
  'seeing': { label: 'Seeing', unit: "\"", color: "warning" },
  'filter': { label: 'Filter', unit: "", color: "warning" },
  'maglim': { label: 'Mag limit', unit: " mag", color: "warning" },
  'ostat': { label: 'Ostat', unit: "", color: "warning" },
  'm5': { label: 'm(5")', unit: " mag", color: "warning" },
  "ra": { label: "RA", unit: "°", color: "info" },
  "dec": { label: "Dec", unit: "°", color: "info" },
  "eph_unc": { label: "Eph 3σ", unit: "\"", color: "info" },
  "mu": { label: "μ", unit: "\"/hr", color: "info" },
  "centroid_offset": { label: "Centroid offset", unit: "\"", color: "info" },
  'sangle': { label: 'Sun PA', unit: "°", color: "info" },
  'vangle': { label: 'v PA', unit: "°", color: "info" },
  'stackid': { label: 'Stack ID', unit: "", color: "default" },
  "count": { label: "N images", unit: "", color: "default" },
  "objid": { label: "Object ID", unit: "", color: "default" },
}

export function StyledChip(props) {
  return <Chip
    color="secondary"
    variant="outlined"
    sx={{ margin: 1 }}
    {...props}
  />
}


export default function StackMetadata({ stack }) {
  const geometric = [
    'date',
    'tmtp',
    'rh',
    'delta',
    'phase',
  ];

  const astrometric = [
    "ra",
    "dec",
    "eph_unc",
    "centroid_offset",
    "mu",
    'sangle',
  ];

  const photometric = [
    'filter',
    'm5',
    'ostat',
    'maglim',
    'seeing',
  ];

  const other = [
    'objid',
    'stackid',
    "count"
  ];

  const columns = {
    2: [[...geometric, ...photometric], [...astrometric, ...other]],
    3: [[...geometric, ...other], photometric, astrometric]
  };

  const nColumns = useMediaQuery(theme => theme.breakpoints.down("md")) ? 2 : 3;

  const formatMetadata = (item) => {
    const value = stack[item];
    const { unit } = annotations[item];
    if (item === 'mu') {
      const arcsec_per_thirty = (stack['mu'] / 3600 * 30).toFixed(1);
      return `${value}${unit} = ${arcsec_per_thirty}"/30s`;
    } else if (item === 'date') {
      return stack.date.substring(0, 10);
    } else {

      return `${value}${unit}`;
    }
  };

  return (
    stack && <Grid container>
      {columns[nColumns].map((items, index) =>
        <Grid item key={index} xs={12 / nColumns}>
          {items.map((item) => {
            const { color, label } = annotations[item];
            return (
              <div key={item}>
                <StyledChip color={color} variant="filled" label={label} />
                <Typography variant="body1" component="span" sx={{ display: "inline" }}>{formatMetadata(item)}</Typography>
              </div>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
}
